@import './variables.scss';

// Header
#main-header {
  background-color: white;
  padding: 60px;
  flex-direction: initial;
  align-items: center;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease;

  &.smaller {
      padding: 5px;
  }

  .header-container {
      flex-wrap: nowrap;

      .header-links {
          width: 100%;
          padding: 0 60px;
      }
  }

  .logo-link {
    margin: 0;

    .logo {
        height: 50px;
    }
  }

  a {
    font-family: 'Allerta Stencil' !important;
    color: black;
    text-decoration: none;
    margin: 0 30px;
    font-size: 22px;
  }

  .language-bar {
      margin: 0;
      padding: 0;
      max-width: fit-content;
  }

  .change-language {
    margin: 0 !important;
    cursor: pointer;
    color: black;
    font-size: 16px !important;
  }

  @media (max-width: $lg-max) {
      padding: 60px 20px;

      &.visible {
        background-color: $brand-bg-medium-yellow;

        .header-container {
          .header-links-container {
            .close-menu {
              display: block;
            }

            .open-menu {
              display: none;
            }
          }
        }
      }

      .header-container {
          flex-direction: row-reverse;
          justify-content: initial;

          .header-links-container {
            max-width: fit-content;

            .header-links {
                width: 100%;
                display: flex;
                height: 0;
                padding: 0;
                visibility: hidden;
                flex-direction: column;
                position: absolute;
                right: 0;
                top: 156px;
                background-color: white;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                transition: all .5s ease;
        
                &.visible {
                    background-color: $brand-bg-medium-yellow;
                    visibility: visible;
                    height: 100vh;
                    padding: 30px 60px;
        
                    a {
                        display: block;
                    }
                }
        
                a {
                    margin: 10px 0;
                    display: none;
                }
            }

            .close-menu {
              display: none;
            }
        }
     }
  }
}