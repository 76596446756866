@import './variables.scss';

// Home page carousel
.project-carousel {
  margin: 30px 0;

  .swiper-container-greens, .swiper-container-zip, .swiper-container-adventure {
      width: 100%;

      .swiper-slide {
          img {
              width: 100%;
              height: auto;
          }

          .slide-text-container {
            opacity: 0;
            position: absolute;
            top: 10%;
            left: 15%;
            padding: 30px;
            color: white;
            background: rgba(0, 0, 0, 0.5);
            width: 60%;
            transition: opacity 1s ease-in-out;
            border: 0px solid black;
            border-radius: 25px;
          }
      }

      .swiper-slide-active {
        .slide-text-container {
            opacity: 1;
        }
      } 

      .swiper-button {
          color: white;
      }

      .swiper-button-prev {
          left: 4%;
      }

      .swiper-button-next {
          right: 4%;
      }

      .swiper-pagination-bullet {
          opacity: 1;
      }

      .swiper-pagination-bullet-active {
          background-color: white;
      }
  }
}
