@import './variables.scss';

.nav-bar-container {
  margin-bottom: 20px;
  min-height: calc(100vh - 180px);
}

.product-card {
  transform: scale(0.98);
  cursor: pointer;

  &:hover {
    transform: scale(1);
  }

  .product-image {
    height: calc(100vh - 500px);
  }
  
  .product-description {
    height: 300px;
    padding: 30px;

    &.zip-area {
      background: $brand-bg-light-green;
    }

    &.adventure-golf {
      background: $brand-bg-medium-yellow;
    }
  }

  .link-button {
    border-radius: 4px;
    position: absolute;
    bottom: 0;
  }
}
