// Media query variables

$xxs: 375px;
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1600px;

$xxs-max: $xxs - 1px;
$xs-max: $xs - 1px;
$sm-max: $sm - 1px;
$md-max: $md - 1px;
$lg-max: $lg - 1px;
$xl-max: $xl - 1px;

// Buttons

$brand-green: #122710;
$link-button-bg: #4a6b5a;
$link-button-hover: black;

// Block backgrounds

$brand-bg-dark-green: #cfdac8;
$brand-bg-medium-green: #eef5e8;
$brand-bg-light-green: #e8eae6;

$brand-bg-dark-yellow: #fadcac;
$brand-bg-medium-yellow: #efebdd;