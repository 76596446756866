@import './variables.scss';

.hero-container {
  position: relative;
  background-color: #fff;
  transition: background-color 4s linear;
  -moz-transition: background-color 4s linear;
  -o-transition: background-color 4s linear;
  -webkit-transition: background-color 4s linear;
  color: white;
  min-height: calc(100vh - 180px);
  padding: 100px;

  .text-area {
    text-align: right;
  }

  &.transition {
    background-color: $brand-green;
  }

  @media (max-width: $md-max) {
    height: 400px;
    padding: 20px;
  }

  .link-button {
    position: absolute;
    bottom: 5%;
    right: 5%;
  }

  .golf-icon {
    font-size: 80px;
  }
}

