@import './variables.scss';

.contact-form-container {
  flex-direction: column;
  text-align: -webkit-center;
  background-color: $brand-bg-light-green;
  
  .MuiGrid-item {
      margin: 10px 0;
  }

  fieldset {
    border-color: black;
  }

  h3 {
    display: flex;
    align-items: center;
    font-family: "Allerta Stencil";
  }

  .contact-form-icon {
    font-size: 2.5rem;
    margin-right: 10px;
  }

  @media (max-width: $sm-max) {
    &.block {
      padding: 60px 10px !important;
    }
  }
}
