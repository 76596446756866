@import './variables.scss';

.media-teaser {
  margin-left: auto !important;
  margin-right: auto !important;

  .media-link {
    text-decoration: none !important;
  }

  .media-card {
    height: 100%;
    transform: scale(0.98);
    transition: 0.15s ease-in-out;

    .media-image {
      height: 400px;
    }

    .media-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 250px;

      .link-button {
        width: fit-content;
        margin-top: 20px !important;
        border-radius: 4px;
      }
    }

    &:hover {
      transform: scale(1);
    }
  }

  @media (max-width: 1279px) {
    .media-card {
      .media-image {
        height: 300px;
      }
    }
  }
}