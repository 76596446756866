// This file is the main style file compiled automatically for browsers
// Import all scss files here
@import './variables.scss';
@import './carousel.scss';
@import './header.scss';
@import './footer.scss';
@import './contactform.scss';
@import './referencelist.scss';
@import './productteaser.scss';
@import './hero.scss';
@import './about.scss';
@import './mediateaser.scss';
@import './blog.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import './productitem.scss';
@import './productsnavbar.scss';

// Common styles
* {
    box-sizing: border-box;
    font-family: 'Allerta Stencil' !important;
}

body {
    display: flex;
    flex-direction: column;

    &.overflow-hidden {
        overflow: hidden;
    }
}

a, p, button, span, label {
    font-family: 'Verdana' !important;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    margin-top: 176px;
    flex: 1;

    @media (max-width: $sm-max) {
        margin-top: 156px;
    }
}

h1 {
    font-size: 2.2rem !important;
    font-weight: 900 !important;
    margin-bottom: 20px !important;
}

h2 {
    font-size: 1.8rem !important;
    font-weight: 400 !important;
    margin-bottom: 20px !important;
}

h3 {
    font-size: 1.7rem !important;
    margin-bottom: 20px !important;
    font-weight: 400 !important;
}

p {
    font-size: 1.3rem !important;
}

.faq {
    .faq-paper {
        background-color: #f6f7d4;
    }
}


// Buttons
button {
    text-transform: none !important;
    width: fit-content;

    &.action-button {
        color: $brand-green !important;
        font-size: 20px !important;

        &:hover {
            background: none !important;
        }
    }
}

button, a {
    &.link-button {
        padding: 20px 60px;
    }
}

.link-button {
    margin: 10px 0 !important;
    font-size: 20px !important;
    background-color: $link-button-bg !important;
    color: white !important;
    text-transform: none !important;

    &:after {
        display: inline-block !important;
        font-style: normal !important;
        font-variant: normal !important;
        text-rendering: auto !important;
        -webkit-font-smoothing: antialiased !important;
        font-family: 'Font Awesome 5 Free' !important;
        content: '\f061' !important;
        font-weight: 900 !important;
        margin-left: 10px !important; 
    }

    &:hover, &:focus {
        background-color: $link-button-hover !important;
    }
}


// Blocks
.block {
    padding: 100px 0;
}

.hidden {
    display: none !important;
}

.bg1 {
    background-color: #f3f3f3;
}

// Cookie consent

.CookieConsent {
    div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .privacy-policy-link {
            color: white;
            float: right;
        }
    }
}

// Privacy policy

.privacy-policy {
    padding: 60px 100px;
}


// Media queries

// 767px
@media (max-width: $sm-max) {
    .logo {
        height: 30px !important;
    }

    button, a {
        &.link-button {
            padding: 5px 25px;
        }
    }

    h1 {
        font-size: 1.5rem !important;
    }

    h2 {
        font-size: 1.2rem !important;
    }

    h3 {
        font-size: 1.1rem !important;
    }

    .block {
        padding: 60px 0 !important;
    }
}

@media (max-width: $lg-max) {
    button, a {
        &.link-button {
            padding: 10px 35px;
        }
    }

    p {
        font-size: 1rem !important;
    }
}

// Hide onward

// 1600px
@media (min-width: $lg) {
    .hide-lg {
        display: none !important;
    }
}

// 1200px
@media (min-width: $md) {
    .hide-md {
        display: none !important;
    }
}

// 768px
@media (min-width: $sm) {
    .hide-sm {
        display: none !important;
    }
}

// 576px
@media (min-width: $xs) {
    .hide-xs {
        display: none !important;
    }
}

// 375px
@media (min-width: $xxs) {
    .hide-xxs {
        display: none !important;
    }
}


// Hide until
@media (max-width: $lg) {
    .show-lg {
        display: none !important;
    }
}

@media (max-width: $md) {
    .show-md {
        display: none !important;
    }
}

@media (max-width: $xs) {
    .show-xs {
        display: none !important;
    }
}
