.blog-container {
  margin-left: auto;
  margin-right: auto;

  .blog-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    transform: scale(0.98);
    transition: 0.15s ease-in-out;

    &:hover {
      transform: scale(1);
    }
  }

  .blog-image {
    height: 400px;
  }

  .blog-content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 250px;

    .blog-author {
      align-items: center;
      margin-bottom: 30px;

      img {
        margin-right: 10px;
      }
    }

    .blog-published {
      position: absolute;
      bottom: 5px;
      font-size: 14px !important;
    }
  }

  @media (max-width: 1279px) {
    .blog-card {
      .blog-image {
        height: 300px;
      }
    }
  }
}

.latest-blog-wrapper {
  justify-content: center;
  align-items: center;
  
  .progress-icon {
    align-self: center;
    justify-self: center;
  }
}