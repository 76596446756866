@import '../styles/variables.scss';

.about-content {
  flex-direction: column;
  padding: 60px;

  .about-header {
    margin-bottom: 40px !important;
  }

  .about-text {
    margin-bottom: 20px;
  }
}

.about-teaser {
  a {
    text-decoration: none;
  }
  
  .about-card {
    display: flex;
    align-items: center;
    height: 100%;
    transform: scale(0.98);
    background-color: $brand-bg-medium-yellow;
    font-style: italic;

    .about-teaser-wrapper {
      padding: 30px;

      .text {
        font-family: 'Allerta Stencil' !important;
        margin-bottom: 15px;
        font-size: 32px !important;
      }
    }

    @media (max-width: $md-max) {
      .about-teaser-wrapper {
        padding: 0 60px;

        .text {
          font-size: 24px !important;
        }
      }
    }
  }
}