@import './variables.scss';

// Footer
#footer {
  display: flex;
  flex-direction: column;
  height: auto;
  bottom: 0;

  .logo {
      height: 30px;
      align-self: center;
  }

  a {
      color: white;
      text-decoration: none;
  }

  h3 {
      margin-top: 0;
  }
  
  .footer-top {
      display: flex;
      background-color: $brand-green;
      padding: 40px 120px;

      .footer-links {
          display: flex;
          flex-direction: column;
      }

      .contact-details {
        color: white;

        .detail {
            margin: 10px 0;
            flex-wrap: nowrap;

            p {
                font-size: 18px !important;
            }
        }
      }

      a {
          margin: 10px 0;
          font-size: 20px;
      }
  }

  .footer-bottom {
      display: flex;
      padding: 5px 20px;
      background-color: $brand-bg-light-green;

      .copyright-container {
        justify-content: space-between;

        .copyright {
            font-size: 14px !important;
        }

        .privacy-policy-link {
            font-size: 14px !important;
            color: black;
            text-decoration: underline;
        }
    }
  }

  @media (max-width: $md-max) {
    .footer-top {
        flex-direction: column;
        padding: 30px;

        .contact-details {
            margin-top: 30px;
        }
    }
  }
}