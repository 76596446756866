.product-teaser {
  transform: scale(0.99);
  border-radius: 4px;
  display: flex;

  a {
    text-decoration: none;
  }

  &.img-right {
    flex-direction: row-reverse;

    .teaser-image {
      img {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .teaser-image {
    display: flex;
    
    img {
      width: 100%;
      border-radius: 4px 0 0 4px;
    }
  }

  .teaser-text {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 20px 60px;

    .link-button {
      margin-top: 30px !important;
    }

    &.green {
      background-color: $brand-bg-light-green;
    }

    &.sand {
      background-color: $brand-bg-medium-yellow;
    }
  }
}