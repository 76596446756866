@import './variables.scss';

.product-header {
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  background: #122710;
  color: white;
  padding: 60px;
  margin: 0;

  h1 {
    margin: 0 !important;
    position: absolute;
    left: 10%;
  }
}
