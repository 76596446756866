.MuiContainer-root.reference-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 120px;
    margin: 10px;

    @media (max-width: $md-max) {
      height: 40px;
    }
  }

  .reference-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 60px;
  }
}